<template lang="html">
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h3 class="display-2">Review Untuk {{ pegawai.peg_nama }}</h3>
      </v-card-title>
      <hr>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <div class="d-flex">
              <v-col cols="3" class="mt-2">
                <label class="display-1">Reviewer Untuk Bulan &nbsp;</label>
              </v-col>
              <v-col cols="3">
                <v-select dense outlined v-model="selectedBulan" :items="bulanList" :loading="loading" label="" item-text="b" item-value="k" hide-details></v-select>
              </v-col>
              <v-col cols="2"></v-col>
              <div class="d-flex flex-column align-end">
                <v-btn small class="primary" @click="autoGenerate()" :loading="loadingAutoGenerate"><v-icon class="mdi mdi-rhombus-split" small></v-icon> &nbsp; Auto Generate</v-btn>
                <v-btn small class="red white--text" @click="hapusReviewer()" :loading="loadingHapusReviewer"><v-icon class="mdi mdi-delete" small></v-icon> &nbsp; Hapus</v-btn>
              </div>
            </div>
            <div class="d-flex justify-space-around">
            <table>
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    <v-card color="yellow lighten-2">
                      <v-card-text>
                        <div>
                          <b>{{ atas.peg_nama }}</b><br>
                          {{ atas.peg_nip }}
                        </div>
                        <v-btn small class="primary" @click="editReviewerPegawai('atas', pegawai)"><v-icon class="mdi mdi-pencil" small></v-icon> &nbsp; Edit</v-btn>
                      </v-card-text>
                    </v-card>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <v-card color="yellow lighten-2">
                      <v-card-text>
                        <div>
                          <b>{{ kiri.peg_nama }}</b><br>
                          {{ kiri.peg_nip }}
                        </div>
                        <v-btn small class="primary" @click="editReviewerPegawai('kiri', pegawai)"><v-icon class="mdi mdi-pencil" small></v-icon> &nbsp; Edit</v-btn>
                      </v-card-text>
                    </v-card>
                  </td>
                  <td>
                    <v-card color="red lighten-4">
                      <v-card-text>
                        <div>
                          <b>{{ peg.peg_nama }}</b><br>
                        {{ peg.peg_nip }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </td>
                  <td>
                    <v-card color="yellow lighten-2">
                      <v-card-text>
                        <div>
                          <b>{{ kanan.peg_nama }}</b><br>
                          {{ kanan.peg_nip }}
                        </div>
                        <v-btn small class="primary" @click="editReviewerPegawai('kanan', pegawai)"><v-icon class="mdi mdi-pencil" small></v-icon> &nbsp; Edit</v-btn>
                      </v-card-text>
                    </v-card>
                  </td>
                </tr>
                <tr v-show="pegawai.jenis_jabatan == 'S' || pegawai.ketua">
                  <td></td>
                  <td>
                    <v-card color="yellow lighten-2">
                      <v-card-text>
                        <div>
                          <b>{{ bawah.peg_nama }}</b><br>
                          {{ bawah.peg_nip }}
                        </div>
                        <v-btn small class="primary" @click="editReviewerPegawai('bawah', pegawai)"><v-icon class="mdi mdi-pencil" small></v-icon> &nbsp; Edit</v-btn>
                      </v-card-text>
                    </v-card>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div></div>
            </div>
          </v-col>
          <v-col cols="4" v-show="direction != ''">
              <label class="display-2">Pilih reviewer sebelah {{ direction }}</label>
              <div :loading="isLoadingReviewers" v-show="isLoadingReviewers">
                Sedang Memuat Data
              </div>
              <v-simple-table class="table table-bordered" v-show="!isLoadingReviewers">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama - NIP</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(reviewer, index) in available_reviewers" v-bind:key="index">
                    <td>{{ index + 1 }}</td>
                    <td><b>{{ reviewer.peg_nama }}</b><br>{{ reviewer.peg_nip }}<br>{{ reviewer.jabatan_nama }}</td>
                    <td><v-btn color="green darken-2 white--text" small :loading="loadingSetReviewer == index" @click="setReviewer(index, reviewer.peg_nip)"><i class="fa fa-check"></i> Pilih</v-btn></td>
                  </tr>
                </tbody>
              </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
          <label class="display-2">Daftar Mereview</label>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[10,15,20]}"
          >
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.nip="{ item }">
              <br><div>{{item.peg_nip}}</div><br>
            </template>
            <template v-slot:item.nama="{ item }">
              <div>{{ item.peg_nama }}</div>
            </template>
            <template v-slot:item.jabatan="{ item }">
              <div>{{item.jabatan_nama ? item.jabatan_nama : (item.jf_nama ? item.jf_nama : item.jfu_nama)}}</div>
            </template>
            <template v-slot:item.hubungan="{ item }">
              <div><b>{{item.hubungan}}</b></div>
            </template>
            <template v-slot:item.aksi="{item, index}">
              <v-btn color="primary" small @click="changeReviewer(item, index)" v-if="item.hubungan == 'Bawahan' || item.hubungan == 'Teman'" :loading="gantiReviewerPegawai == index"><v-icon small>mdi-pencil</v-icon>Edit</v-btn>&nbsp;
              <v-btn color="red white--text" small @click="hapusMereview(item, index)" :loading="hapusMereviewPegawai == index"><v-icon small>mdi-delete</v-icon>Hapus</v-btn>
            </template>
          </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeEvent()" color="yellow darken-1">tutup</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogChangeReviewer" max-width="800px" >
      <v-card>
        <v-card-title>
          <span class="headline">Ganti Mereview</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersChangeReviewer"
            :items="itemsChangeReviewer"
            :loading="loadingChangeReviewer"
          >
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.nip="{ item }">
              <br><div>{{item.peg_nip}}</div><br>
            </template>
            <template v-slot:item.nama="{ item }">
              <div>{{ item.peg_nama }}</div>
            </template>
            <template v-slot:item.jabatan="{ item }">
              <div>{{item.jabatan_nama ? item.jabatan_nama : (item.jf_nama ? item.jf_nama : item.jfu_nama)}}</div>
            </template>
            <template v-slot:item.aksi="{item, index}">
              <v-btn color="primary" small @click="setMereview(item, index)" :loading="gantiReviewerPegawai_ == index"><v-icon>mdi-checklist</v-icon>Pilih</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn @click="dialogChangeReviewer = false" color="yellow darken-1">tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SettingReviewerService from '@/services/SettingReviewerService'
import store from '@/store'
import _g from '../../global'
import axios from 'axios'
import {local} from '@/store/local'
import _ from 'lodash'
var now = new Date()
export default {
  props:{
    pegawai:Object
  },
  data(){
    return{
      filterPegawai:true,
      forceUpdate:false,
      loading:false,
      detailData:{},
      skpd:[],

      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'NIP', value:'nip', sortable:false, class:'header-index-profile'},
        {text:'Nama', value:'nama', sortable:false, class:'header-index-profile'},
        {text:'Jabatan', value:'jabatan', sortable:false, class:'header-index-profile'},
        {text:'Hubungan', value:'hubungan', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
      selectSKPD:'',
      search: '',
      tableValue:{},
      totalKeseluruhan:0,
      rowPerPage:10,
      page:1,
      dialogDetailReviewer: false,
      item:{},
      bulanList:[
        { b:'Januari',k:1},
        { b:'Febuari',k:2},
        { b:'Maret',k:3},
        { b:'April', k:4},
        { b:'Mei', k:5},
        { b:'Juni', k:6},
        { b:'Juli', k:7},
        { b:'Agustus', k:8},
        { b:'September', k:9},
        { b:'Oktober', k:10},
        { b:'November', k:11},
        { b:'Desember', k:12},
      ],
      selectedBulan: null,
      atas: {},
      kiri: {},
      kanan: {},
      bawah: {},
      mereview: {},
      peg:{},
      bulan: (now.getMonth()+1),
      direction: '',
      isLoadingReviewers: false,
      available_reviewers: [],
      hapusMereviewPegawai: -1,
      gantiReviewerPegawai: -1,
      gantiReviewerPegawai_: -1,
      loadingSetReviewer: -1,
      loadingAutoGenerate: false,
      loadingHapusReviewer: false,
      dialogChangeReviewer: false,
      headersChangeReviewer:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'NIP', value:'nip', sortable:false, class:'header-index-profile'},
        {text:'Nama', value:'nama', sortable:false, class:'header-index-profile'},
        {text:'Jabatan', value:'jabatan', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      loadingChangeReviewer: false,
      itemsChangeReviewer:[],
      items:[]
    }
  },

  watch:{
    pegawai(){
      var p = this.pegawai
      this.peg = this.pegawai
      this.updateTable({peg_nip:p.peg_nip, bulan:this.selectedBulan})
    },
  },

  mounted(){
    var peg = this.pegawai
    this.$watch(vm => [vm.selectedBulan], val => {
      this.updateTable({peg_nip:peg.peg_nip, bulan:val[0]})
    })
  },

  created(){
    // this.selectedBulan = this.selectedBulan != null ? this.selectedBulan : (now.getMonth()+1)
    var peg = this.pegawai
    let val = {peg_nip:peg.peg_nip, bulan: this.selectedBulan}
    this.peg = this.pegawai
    this.updateTable(val)
    this.getListSKPD()
    this.selectedBulan = this.bulan
  },

  computed:{
  },

  methods:{
    closeEvent(){
      let val = {itemsPerPage:10, page: 1}
      this.$emit('onClose',false)
      this.$emit('onTableChange',val)
    },
    getListSKPD(){
      var url = "v1/get-data-skpd-satuan-kerja"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_).then(response => {
        let data = []
        _.each(response.data.data, function(value, key, obj){
          data.push({k:key, v: value})
        })
        this.skpd = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    updateTable(val){
      this.loading = true
      this.tableValue = val ? val: this.tableValue
      this.items = []
      var d = new Date()
      var m = (d.getMonth()+1)

      SettingReviewerService.getReviewer({
        nip:this.tableValue.peg_nip,
        bulan:this.tableValue.bulan ? this.tableValue.bulan : m})
      .then((response)=>{
        this.atas = response.data.data.atas
        this.kiri = response.data.data.kiri
        this.kanan = response.data.data.kanan
        this.bawah = response.data.data.bawah
        this.items = response.data.data.mereview
        this.totalKeseluruhan = response.data.data.mereview.length
        this.available_reviewers = []
        this.isLoadingReviewers = false
        this.direction = ''
        console.log(this.items)
        let res = response.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      })
    },

    detailReviewer(item){
      this.item = item
      this.dialogDetailReviewer = true

    },

    editReviewerPegawai(direction, pegawai){
      this.direction = direction
      this.isLoadingReviewers = true
      SettingReviewerService.getAvailableReviewer({
        nip:pegawai.peg_nip,
        bulan:this.selectedBulan ? this.selectedBulan : this.bulan,
        direction:this.direction})
      .then((response)=>{
        this.available_reviewers = response.data
        console.log(this.available_reviewers)
        let res = response.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.isLoadingReviewers = false
      })
    },

    changeReviewer(item, i){
      this.gantiReviewerPegawai = i
      var data = {nip:item.peg_nip,nip_reviewer:this.pegawai.peg_nip}
      this.dialogChangeReviewer = true
      this.itemsChangeReviewer = []
      this.loadingChangeReviewer = true
      SettingReviewerService.getMereview({
        nip:data.nip,
        nip_reviewer:data.nip_reviewer,
        bulan:this.selectedBulan ? this.selectedBulan : this.bulan})
      .then((response)=>{
        this.itemsChangeReviewer = response.data.data
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loadingChangeReviewer = false
        this.gantiReviewerPegawai = -1
      })
    },

    hapusMereview(item, i){
      if(!confirm('Apakah Anda Yakin Akan Menghapus Reviewer?')) return;
      this.hapusMereviewPegawai = i
      SettingReviewerService.hapusMereview({
        nip_reviewer: this.pegawai.peg_nip,
        nip: item.peg_nip,
        bulan:this.selectedBulan ? this.selectedBulan : this.bulan})
      .then((response)=>{
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Data Mereview Berhasil Dihapus!'), color:'success'})
          let val = {peg_nip:item.peg_nip, bulan: this.selectedBulan}
          this.updateTable(val)
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.hapusMereviewPegawai = -1
      })
    },

    setMereview(item, i){
      this.gantiReviewerPegawai_ = i
      SettingReviewerService.setMereview({
        nip: item.peg_nip,
        nip_reviewer: item.nip_reviewer,
        nip_sebelum:item.nip_sebelum,
        bulan:this.selectedBulan ? this.selectedBulan : this.bulan })
      .then((response)=>{
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Data Mereview Berhasil Diubah!'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        let val = {peg_nip:this.pegawai.peg_nip, bulan: this.selectedBulan}
        this.updateTable(val)
      }).finally(()=>{
        this.gantiReviewerPegawai_ = -1
        this.dialogChangeReviewer = false
      })
    },

    setReviewer(i, nip_reviewer){
      this.loadingSetReviewer = i
      SettingReviewerService.setReviewer({
        peg_nip:this.pegawai.peg_nip,
        bulan:this.selectedBulan ? this.selectedBulan : this.bulan,
        direction:this.direction,
        nip_reviewer:nip_reviewer})
      .then((response)=>{
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Data Reviewer Berhasil Diubah!'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        let val = {peg_nip:this.pegawai.peg_nip, bulan: this.selectedBulan}
        this.updateTable(val)
      }).finally(()=>{
        this.loadingSetReviewer = -1
      })
    },

    autoGenerate(){
      this.loadingAutoGenerate = true
      SettingReviewerService.autoGenerate({
        peg_nip:this.pegawai.peg_nip,
        bulan:this.selectedBulan ? this.selectedBulan : this.bulan})
      .then((response)=>{
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Generate Data Reviewer Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        let val = {peg_nip:this.pegawai.peg_nip, bulan: this.selectedBulan}
        this.updateTable(val)
      }).finally(()=>{
        this.loadingAutoGenerate = false
      })
    },

    hapusReviewer(){
      this.loadingHapusReviewer = true
      SettingReviewerService.hapusReviewer({
        peg_nip:this.pegawai.peg_nip,
        bulan:this.selectedBulan ? this.selectedBulan : this.bulan})
      .then((response)=>{
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Data Reviewer Berhasil Dihapus!'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        let val = {peg_nip:this.pegawai.peg_nip, bulan: this.selectedBulan}
        this.updateTable(val)
      }).finally(()=>{
        this.loadingHapusReviewer = false
      })
    }
  },
}
</script>

<style lang="css" scoped>
</style>
